<template>
    <ul class="pagination-list">
        <li class="pagination-list__item"><a class="pagination-list__link" href="#"><i class="eicon e-arrow-left"></i></a></li>
        <li class="pagination-list__item pagination-list__item--active"><a class="pagination-list__link" href="#">1</a></li>
        <li class="pagination-list__item"><a class="pagination-list__link" href="#">2</a></li>
        <li class="pagination-list__item"><a class="pagination-list__link" href="#">3</a></li>
        <li class="pagination-list__item"><a class="pagination-list__link" href="#"><i class="eicon e-arrow-right"></i></a></li>
    </ul>
</template>

<script>
    export default {

    }
</script>
